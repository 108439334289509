import userManager from "@/services/userManager"
import moment from "moment"
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: RouteConfig[] = [
  {
    path: '',
    redirect: "/app"
  },
  {
    path: '/login',
    component: () => import('../views/Login.vue'),
    beforeEnter(from, to, next) {
      const userInfo = userManager.getUserInfo()
      if (userInfo) {
        next("/app")
      } else {
        next()
      }
    },
  },
  {
    path: '/app',
    component: () => import('../views/app/Layout.vue'),
    beforeEnter(from, to, next) {
      const userInfo = userManager.getUserInfo()
      if (!userInfo) {
        next("/login")
      } else {
        next()
      }
    },
    children: [
      {
        path: "",
        redirect: "markets"
      },
      {
        path: "change_password",
        component: () => import('../views/app/ChangePassword.vue'),
      },
      {
        path: "countries",
        component: () => import('../views/app/Countries.vue'),
      },
      {
        path: "markets",
        component: () => import('../views/app/Markets.vue'),
      },
      {
        path: "departments",
        component: () => import('../views/app/Departments.vue'),
      },
      {
        path: "teams",
        component: () => import('../views/app/Teams.vue'),
      },
      {
        path: "users",
        component: () => import('../views/app/Users.vue'),
      },
      {
        path: "shipping_companies",
        component: () => import('../views/app/ShippingCompanies.vue'),
      },
      {
        path: "shipping_companies/:shippingCompanyId/accounts",
        component: () => import('../views/app/shipping-companies/Accounts.vue'),
      },
      {
        path: "purchase_shipping_companies",
        component: () => import('../views/app/PurchaseShippingCompanies.vue'),
      },
      {
        path: "purchase_currencies",
        component: () => import('../views/app/PurchaseCurrencies.vue'),
      },
      {
        path: "mkt_platforms",
        component: () => import('../views/app/MktPlatforms.vue'),
      },
      {
        path: "product_categories",
        component: () => import('../views/app/ProductCategories.vue'),
      },
      {
        path: "warehouse_transfer_commands",
        component: () => import('../views/app/WarehouseTransferCommands.vue'),
      },
      {
        path: "cost_modify_commands",
        component: () => import('../views/app/CostModifyCommands.vue'),
      },
      {
        path: "action_logs",
        component: () => import('../views/app/ActionLogs.vue'),
      },
      {
        path: "daily_bonus_settings",
        component: () => import('../views/app/DailyBonusSettings.vue'),
      },
      {
        path: "monthly_costs",
        component: () => import('../views/app/MonthlyCosts.vue'),
      },
      {
        path: "salaries",
        component: () => import('../views/app/Salaries.vue'),
        children: [
          {
            path: "",
            redirect: `${moment().startOf("month").valueOf()}/teams`,
          },
          {
            path: ":month/teams",
            component: () => import("../views/app/salaries/Teams.vue"),
          },
          {
            path: ":month/users",
            component: () => import("../views/app/salaries/Users.vue"),
          },
          {
            path: ":month/team_committed_data",
            component: () => import("../views/app/salaries/TeamCommittedData.vue"),
          },
          {
            path: ":month/user_committed_data",
            component: () => import("../views/app/salaries/UserCommittedData.vue"),
          },
        ]
      },
      {
        path: "team_kpi",
        component: () => import("../views/app/salaries/Teams.vue"),
      },
      {
        path: "user_salaries",
        component: () => import("../views/app/salaries/Users.vue"),
      },
      {
        path: "setting",
        component: () => import('../views/app/Setting.vue'),
      },

      {
        path: "country_statistics",
        component: () => import("../views/app/CountryStatistics.vue"),
        children: [
          {
            path: "",
            redirect: "index",
          },
          {
            path: "index",
            component: () => import("../views/app/country-statistics/Index.vue"),
          },
          {
            path: "product_groups",
            component: () => import("../views/app/country-statistics/ProductGroups.vue"),
          },
          {
            path: "in_transit_products",
            component: () => import("../views/app/country-statistics/InTransitProducts.vue"),
          },
          {
            path: "in_warehouse_products",
            component: () => import("../views/app/country-statistics/InWarehouseProducts.vue"),
          },
          {
            path: "purchase_commands",
            component: () => import("../views/app/country-statistics/PurchaseCommands.vue"),
          },
        ],
      },
      {
        path: "statistics/:statisticType",
        component: () => import("../views/app/Statistics.vue"),
        children: [
          {
            path: "",
            redirect: userManager.checkRole(["mkt", "rd"], true) && "teams_daily" || "index",
          },
          {
            path: "index",
            component: () => import("../views/app/statistics/Index.vue"),
          },
          {
            path: "wholesale_customers",
            component: () => import("../views/app/statistics/WholesaleCustomers.vue"),
          },
          {
            path: "profit",
            component: () => import("../views/app/statistics/Profit.vue"),
          },
          {
            path: "profit_mkt_users",
            component: () => import("../views/app/statistics/ProfitMktUsers.vue"),
          },
          {
            path: "shops_daily",
            component: () => import("../views/app/statistics/ShopsDaily.vue"),
          },
          {
            path: "shops_monthly",
            component: () => import("../views/app/statistics/ShopsMonthly.vue"),
          },
          {
            path: "shops",
            component: () => import("../views/app/statistics/Shops.vue"),
          },
          {
            path: "teams_daily",
            component: () => import("../views/app/statistics/TeamsDaily.vue"),
          },
          {
            path: "teams_monthly",
            component: () => import("../views/app/statistics/TeamsMonthly.vue"),
          },
          {
            path: "departments_daily",
            component: () => import("../views/app/statistics/DepartmentsDaily.vue"),
          },
          {
            path: "mkt_users",
            component: () => import("../views/app/statistics/MktUsers.vue"),
          },
          {
            path: "mkt_users_daily",
            component: () => import("../views/app/statistics/MktUsersDaily.vue"),
          },
          {
            path: "creators",
            component: () => import("../views/app/statistics/Creators.vue"),
          },
          {
            path: "shipping_companies",
            component: () => import("../views/app/statistics/ShippingCompanies.vue"),
          },
          {
            path: "shipping_companies_by_dxk_time",
            component: () => import("../views/app/statistics/ShippingCompaniesByDxkTime.vue"),
          },
          {
            path: "shipping_company_accounts",
            component: () => import("../views/app/statistics/ShippingCompanyAccounts.vue"),
          },
          {
            path: "not_yet_sent_orders",
            component: () => import("../views/app/statistics/NotYetSentOrders.vue"),
          },
          {
            path: "in_transit_products",
            component: () => import("../views/app/country-statistics/InTransitProducts.vue"),
          },
          {
            path: "in_warehouse_products",
            component: () => import("../views/app/country-statistics/InWarehouseProducts.vue"),
          },
          {
            path: "product_groups",
            component: () => import("../views/app/statistics/ProductGroups.vue"),
          },
          {
            path: "return_product_groups",
            component: () => import("../views/app/statistics/ReturnProductGroups.vue"),
          },
          {
            path: "to_be_sent_pcs",
            component: () => import("../views/app/statistics/ToBeSentPcs.vue"),
          },
        ],
      },
      {
        path: "markets/:marketId/",
        component: () => import('../views/app/MarketDetail.vue'),
        children: [
          {
            path: "",
            redirect: "orders"
          },
          {
            path: "orders",
            component: () => import("../views/app/market-detail/Orders.vue"),
            children: [
              {
                path: "",
                redirect: userManager.checkRole(["vdl", "vd"], true) && "index" || userManager.checkRole(["telesale"], true) && "telesale_check" || userManager.checkRole(["cdc"], true) && "location_check" || userManager.checkRole(["qlk"], true) && "pick_up_prepare" || "index",
              },
              {
                path: "index",
                component: () => import("../views/app/market-detail/orders/Index.vue"),
              },
              {
                path: "invalid_check",
                component: () => import("../views/app/market-detail/orders/InvalidCheck.vue"),
              },
              {
                path: "telesale_check",
                component: () => import("../views/app/market-detail/orders/TelesaleCheck.vue"),
              },
              {
                path: "location_check",
                component: () => import("../views/app/market-detail/orders/LocationCheck.vue"),
              },
              {
                path: "pick_up_prepare",
                component: () => import("../views/app/market-detail/orders/PickUpPrepare.vue"),
              },
              {
                path: "pick_up_ready",
                component: () => import("../views/app/market-detail/orders/PickUpReady.vue"),
              },
              {
                path: "to_be_picked_up",
                component: () => import("../views/app/market-detail/orders/ToBePickedUp.vue"),
              },
              {
                path: "to_be_delivered",
                component: () => import("../views/app/market-detail/orders/ToBeDelivered.vue"),
              },
              {
                path: "delivered",
                component: () => import("../views/app/market-detail/orders/Delivered.vue"),
              },
              {
                path: "telesale_confirm",
                component: () => import("../views/app/market-detail/orders/TelesaleConfirm.vue"),
              },
            ]
          },
          {
            path: "statistics",
            component: () => import("../views/app/market-detail/Statistics.vue"),
            children: [
              {
                path: "",
                redirect: "index",
              },
              {
                path: "index",
                component: () => import("../views/app/market-detail/statistics/Index.vue"),
              },
              {
                path: "telesale",
                component: () => import("../views/app/market-detail/statistics/Telesale.vue"),
              },
              {
                path: "users",
                component: () => import("../views/app/market-detail/statistics/Users.vue"),
                children: [
                  {
                    path: "",
                    redirect: "index",
                  },
                  {
                    path: "index",
                    component: () => import("../views/app/market-detail/statistics/users/Index.vue"),
                  },
                  {
                    path: ":userId",
                    component: () => import("../views/app/market-detail/statistics/users/Detail.vue"),
                  },
                ]
              },
            ]
          },
          {
            path: "product_combos",
            component: () => import("../views/app/market-detail/ProductCombos.vue"),
          },
          {
            path: "product_combo_discounts",
            component: () => import("../views/app/market-detail/ProductComboDiscounts.vue"),
          },
          {
            path: "mkt_links",
            component: () => import("../views/app/market-detail/MktLinks.vue"),
          },
          {
            path: "mkt_costs",
            component: () => import("../views/app/market-detail/MktCosts.vue"),
          },
          {
            path: "shops",
            component: () => import("../views/app/market-detail/Shops.vue"),
          },
          {
            path: "warehouse_exports",
            component: () => import("../views/app/market-detail/WarehouseExports.vue"),
          },
        ]
      },
      {
        path: "countries/:countryId/",
        component: () => import('../views/app/CountryDetail.vue'),
        children: [
          {
            path: "",
            redirect: "products"
          },
          {
            path: "product_groups_advanced",
            component: () => import("../views/app/country-detail/ProductGroupsAdvanced.vue"),
          },
          {
            path: "product_groups/:productGroupId/products",
            component: () => import("../views/app/country-detail/product-groups/Products.vue"),
          },
          {
            path: "products",
            component: () => import("../views/app/country-detail/Products.vue"),
          },
          {
            path: "product_prices",
            component: () => import("../views/app/country-detail/ProductPrices.vue"),
          },
          {
            path: "purchase_commands",
            component: () => import("../views/app/country-detail/PurchaseCommands.vue"),
          },
          {
            path: "warehouse_imports",
            component: () => import("../views/app/country-detail/WarehouseImports.vue"),
          },
          {
            path: "warehouse_returns",
            component: () => import("../views/app/country-detail/WarehouseReturns.vue"),
          },
          {
            path: "warehouse_returns_scan",
            component: () => import("../views/app/country-detail/WarehouseReturnsScan.vue"),
          },
          {
            path: "warehouse_returns_scan_history",
            component: () => import("../views/app/country-detail/WarehouseReturnsScanHistory.vue"),
          },
          {
            path: "warehouses",
            component: () => import("../views/app/country-detail/Warehouses.vue"),
          },
          {
            path: "warehouse_reset_commands",
            component: () => import("../views/app/country-detail/WarehouseResetCommands.vue"),
          },
        ]
      },
      {
        path: "ads",
        component: () => import("../views/app/Ads.vue"),
        children: [
          {
            path: "",
            redirect: "agencies",
          },
          {
            path: "agencies",
            component: () => import("../views/app/ads/Agencies.vue"),
          },
          {
            path: "cards",
            component: () => import("../views/app/ads/Cards.vue"),
          },
          {
            path: "costs",
            component: () => import("../views/app/ads/Costs.vue"),
          },
          {
            path: "rd_costs",
            component: () => import("../views/app/ads/RdCosts.vue"),
          },
          {
            path: "bills",
            component: () => import("../views/app/ads/Bills.vue"),
          },
          {
            path: "statistics",
            component: () => import("../views/app/ads/Statistics.vue"),
            children: [
              {
                path: "",
                redirect: userManager.checkRole(["mkt", "tp", "rd", "rdl"], true) ? "mkt_users" : "agencies",
              },
              {
                path: "agencies",
                component: () => import("../views/app/ads/statistics/Agencies.vue"),
              },
              {
                path: "balances",
                component: () => import("../views/app/ads/statistics/Balances.vue"),
              },
              {
                path: "mkt_users",
                component: () => import("../views/app/ads/statistics/MktUsers.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "ads/agencies/:adsAgencyId",
        component: () => import("../views/app/AdsAgencyDetail.vue"),
        children: [
          {
            path: "",
            redirect: "accounts",
          },
          {
            path: "accounts",
            component: () => import("../views/app/ads-agency-detail/Accounts.vue"),
          },
          {
            path: "currencies",
            component: () => import("../views/app/ads-agency-detail/Currencies.vue"),
          },
          {
            path: "balance_changes",
            component: () => import("../views/app/ads-agency-detail/BalanceChanges.vue"),
          },
          {
            path: "statistics",
            component: () => import("../views/app/ads-agency-detail/Statistics.vue"),
            children: [
              {
                path: "",
                redirect: userManager.checkRole(["mkt", "tp", "rd", "rdl"], true) ? "mkt_users" : "balances",
              },
              {
                path: "balances",
                component: () => import("../views/app/ads-agency-detail/statistics/Balances.vue"),
              },
              {
                path: "bills",
                component: () => import("../views/app/ads-agency-detail/statistics/Bills.vue"),
              },
              {
                path: "mkt_users",
                component: () => import("../views/app/ads-agency-detail/statistics/MktUsers.vue"),
              },
              {
                path: "accounts",
                component: () => import("../views/app/ads-agency-detail/statistics/Accounts.vue"),
              },
              {
                path: "days",
                component: () => import("../views/app/ads-agency-detail/statistics/MktUsers.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "payment",
        component: () => import("../views/app/Payment.vue"),
        children: [
          {
            path: "",
            redirect: "partners",
          },
          {
            path: "partners",
            component: () => import("../views/app/payment/Partners.vue"),
          },
          {
            path: "receive_accounts",
            component: () => import("../views/app/payment/ReceiveAccounts.vue"),
          },
          {
            path: "vnd_transactions",
            component: () => import("../views/app/payment/VndTransactions.vue"),
          },
          {
            path: "transactions",
            component: () => import("../views/app/payment/Transactions.vue"),
          },
          {
            path: "shop_wallet_transactions",
            component: () => import("../views/app/payment/ShopWalletTransactions.vue"),
          },
          {
            path: "statistics",
            component: () => import("../views/app/payment/Statistics.vue"),
            children: [
              {
                path: "",
                redirect: "partners",
              },
              {
                path: "partners",
                component: () => import("../views/app/payment/statistics/Partners.vue"),
              },
              {
                path: "shops",
                component: () => import("../views/app/payment/statistics/Shops.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "payment/partners/:paymentPartnerId",
        component: () => import("../views/app/PaymentPartnerDetail.vue"),
        children: [
          {
            path: "",
            redirect: "accounts",
          },
          {
            path: "accounts",
            component: () => import("../views/app/payment-partner-detail/Accounts.vue"),
          },
        ],
      },
    ]
  },
]

const router = new VueRouter({
  base: "/",
  routes
})

export default router
